import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
//import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import { GatsbyContext } from "../context/context"
import sublinks_ko from "../constants/links-ko"
import sublinks from "../constants/links"
import ServiceInfo from "../components/ServiceInfo"

const IndexPage = ({ location }) => {
  //console.log("IndexPage location=", location)
  const {
    defaultLang,
    setDefaultLang,
    language,
    setLanguage,
    links,
    setLinks,
  } = useContext(GatsbyContext)

  useEffect(() => {
    const userLang =
      typeof navigator === `undefined`
        ? "ko"
        : navigator && navigator.language && navigator.language.split("-")[0]
    //console.log("userLang=", userLang)
    setDefaultLang(userLang)
    let currLang = userLang
    if (!language) {
      setLanguage(userLang)
    } else {
      currLang = language
    }
    if (currLang !== "ko") {
      navigate(`/en`)
    }
  }, [language])

  return (
    <Layout location={location}>
      <SEO title="Home" />
      <Hero />
      <ServiceInfo />
    </Layout>
  )
}

export default IndexPage
